/*! 2025-4-2 20:17:05 */
[data-v-14aa5c60]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.change-wrapper[data-v-14aa5c60]{display:flex;margin-top:10px;margin-left:10px;align-items:center}.change-wrapper p[data-v-14aa5c60]{margin-right:10px}.change-wrapper i[data-v-14aa5c60]{margin:0 5px}.mr15[data-v-14aa5c60]{margin-right:15px}

[data-v-092239da]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.business-transfer-detail-table-wrapper[data-v-092239da]{max-width:90%}

[data-v-119b3bbf]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.business-transfer-detail-table-wrapper[data-v-119b3bbf]{width:520px}

[data-v-57bcb08e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.mt20[data-v-57bcb08e]{margin-top:20px}.footer[data-v-57bcb08e]{margin-top:15px}


/*# sourceMappingURL=chunk-5a0794b0.cd1279b0.css.map*/